import React from 'react';
import gql from 'graphql-tag'
import ReactDOM from 'react-dom';
import firebase from 'firebase/app'
import 'firebase/auth'
import { connect, Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-common'
import { configureStore } from './redux/store';
import { createClient } from './graphql/core/client'
import FirebaseLogin from './components/core/login/FirebaseLogin'
import schema from './graphql/schema'
import { createPilotTheme } from './themes'
import { setUserProfile } from './redux/actions'
import pilotBanner from './resources/img/pilot-blue.png'
import ErrorBoundary from './components/core/errors/ErrorBoundary';
import App from './App'    

const resolveUserProfileHof = (client) => {
  return async (userId) => {
    return client.query({
      query: gql`
        query {
          ProfileInfo {
            id
            name
            role
            groups {
              node {
                id
              }
            }
            disassemblyEnabled
          }
        }
      `
    })
    .then(results => {
      const userProfile = results.data.ProfileInfo
      userProfile.groups = userProfile.groups.map(r => r.node.id)
      return userProfile
    })
  }
}

(async () => {

  let firebaseConfig
 
  // development: pull configs from .env 
  if (process.env.NODE_ENV === 'development') {
    firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID
    }
  }
  // production: pull configs from firebase hosting
  else {
    const response = await fetch('/__/firebase/init.json')
    if (!response.ok) {
      throw new Error(response.statusText)
    }
    const body = await response.text()
    firebaseConfig = JSON.parse(body)
  }

  // Custom overrides for hosted Firebase auth to use the CNAME'd domains:
  switch(firebaseConfig.projectId) {
    case 'pilot-security-staging':
      firebaseConfig.authDomain = 'auth.staging.pilot-security.com'
      break
    case 'pilot-security-preview':
      firebaseConfig.authDomain = 'auth.preview.pilot-security.com'
      break
    case 'pilot-security-prod':
      firebaseConfig.authDomain = 'auth.app.pilot-security.com'
      break
    default:
      break
  }

  // initialize firebase
  firebase.initializeApp(firebaseConfig)
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  
  // initialize clients
  const theme = createPilotTheme()
  const reduxStore = configureStore()
  const apolloClient = createClient(schema)

  // configure login component
  const Login = connect(
    (state) => {
      const { userProfile } = state
      return { 
        userProfile,
        resolveUserProfile: resolveUserProfileHof(apolloClient)
      }
    },
    { setUserProfile }
  )(FirebaseLogin)

  // render app
  ReactDOM.render(
      <ApolloProvider client={apolloClient}>
        <Provider store={reduxStore} >
            <MuiThemeProvider theme={theme}>
              <Login>
                <BrowserRouter>
                  <ErrorBoundary banner={pilotBanner}>
                    <App />
                  </ErrorBoundary>
                </BrowserRouter>
              </Login>
            </MuiThemeProvider>
        </Provider>
      </ApolloProvider>
    , document.getElementById('root')
  );
})()
