import React from 'react'
import gql from 'graphql-tag'
import {Button, Icon} from 'semantic-ui-react'


function runHashashin(client, submissionId, sethashashinRan) {
    console.log('run hashashin!')
    client.mutate({
        mutation: gql`
            mutation RunHashashin($input: RunHashashinInput) {
                RunHashashin(input: $input)
            }
        `,
        variables: {
            input: {'submissionId': submissionId}
        }
    })
        .then(() => {
            console.log('hashashin running!')
            sethashashinRan('true');
        })
        .catch(e => {
            console.error(e)
            sethashashinRan('false');
        })
}

const useStateWithLocalStorage = localStorageKey => {
    const [value, setValue] = React.useState(
        localStorage.getItem(localStorageKey) || 'false'
    )

    React.useEffect(() => {
        localStorage.setItem(localStorageKey, value)
    }, [value, localStorageKey])

    return [value, setValue]
}

let RunHashashinButton = (props) => {
    const {submissionId, client} = props
    const [hashashinRan, sethashashinRan] = useStateWithLocalStorage(`pilot-${submissionId}-hashashin-ran`)
    const wasRan = hashashinRan === 'true'
    const styling = (wasRan) ? {
        size: 'tiny',
        color: 'grey',
        style: {'margin-bottom': '10px'},
    } : {}
    return (
        <>
            <Button
                color='blue'
                onClick={wasRan ? null : () => {
                    runHashashin(client, submissionId, sethashashinRan);
                }}
                {...styling}
            >
                {wasRan ? (<><Icon name='refresh'/> Running</>) : (<><Icon name='play'/> Run Hashashin</>)}

            </Button>
        </>
    )
};


export default RunHashashinButton;
