import React from 'react'
import PageSection from '../../../../../components/core/page/PageSection'
import RunHashashinButton from "../../../../../components/RunHashashinButton";


class IndentifyLibraries extends React.Component {

    render() {
        const {userProfile, submissionId, client} = this.props;
        const shouldShowFeature = (
            userProfile
            && userProfile.disassemblyEnabled
            && (
                userProfile.role === 'admin'
                || userProfile.name.endsWith('@riverloopsecurity.com')
                || userProfile.name.endsWith('@twosixtech.com'))
        );
        return (shouldShowFeature) ? (
            <PageSection title='[BETA] Advanced Binary Identification'>
                <RunHashashinButton submissionId={submissionId} client={client}/>
            </PageSection>
        ) : <></>;
    }
}

export default IndentifyLibraries;
